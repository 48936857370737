import type {
  BrandPage,
  Category,
  PartnerPage,
  ScrapedProductOutput,
  SearchOverlay,
} from "@/__generated__/graphql";
import { makeVar, useReactiveVar } from "@apollo/client";

interface ScrapedProductState {
  scrapedProduct: ScrapedProductOutput | null;
  isLoading: boolean;
}

// State to hold the scraped data from link pasting
export const scrapedProductState = makeVar<ScrapedProductState>({
  scrapedProduct: null,
  isLoading: false,
});
export const useScrapedProductState = () => useReactiveVar(scrapedProductState);
export const setScrapedProductState = (newValue: ScrapedProductState) =>
  scrapedProductState(newValue);

// State to hold the uploaded base64 string from image search
export const imageSearchUploadState = makeVar<string | undefined>(undefined);
export const useImageSearchUploadState = () =>
  useReactiveVar(imageSearchUploadState);
export const setImageSearchUploadState = (newValue: string | undefined) =>
  imageSearchUploadState(newValue);

// State to hold product image urls to use for search via shop more experience
export const shopMoreImageUrlState = makeVar<string[] | undefined>(undefined);
export const useShopMoreImageUrlState = () =>
  useReactiveVar(shopMoreImageUrlState);
export const setShopMoreImageUrlState = (newValue: string[] | undefined) =>
  shopMoreImageUrlState(newValue);

// State to hold the category menu fetched from Contentful
export const categoryMenuDataState = makeVar<Category[] | undefined>(undefined);
export const useCategoryMenuDataState = () =>
  useReactiveVar(categoryMenuDataState);
export const setCategoryMenuDataState = (newValue: Category[] | undefined) =>
  categoryMenuDataState(newValue);

// State to hold the category menu fetched from Contentful
export const brandPagesState = makeVar<BrandPage[] | undefined>(undefined);
export const useBrandPagesState = () => useReactiveVar(brandPagesState);
export const setBrandPagesState = (newValue: BrandPage[] | undefined) =>
  brandPagesState(newValue);

// State to hold the category menu fetched from Contentful
export const partnerPagesState = makeVar<PartnerPage[] | undefined>(undefined);
export const usePartnerPagesState = () => useReactiveVar(partnerPagesState);
export const setPartnerPagesState = (newValue: PartnerPage[] | undefined) =>
  partnerPagesState(newValue);

// State to hold the search overlay data fetched from Contentful
export const searchOverlayDataState = makeVar<SearchOverlay | undefined>(
  undefined
);
export const useSearchOverlayDataState = () =>
  useReactiveVar(searchOverlayDataState);
export const setSearchOverlayDataState = (
  newValue: SearchOverlay | undefined
) => searchOverlayDataState(newValue);

// State that determines whether/not we show the search feedback prompt
export const showSearchFeedbackState = makeVar<boolean>(false);
export const useShowSearchFeedbackState = () =>
  useReactiveVar(showSearchFeedbackState);
export const setShowSearchFeedbackState = (newValue: boolean) =>
  showSearchFeedbackState(newValue);

// State that determines whether/not we show the scrape results sidebar
export const showScrapeResultsSidebarState = makeVar<boolean>(true);
export const useShowScrapeResultsSidebar = () =>
  useReactiveVar(showScrapeResultsSidebarState);
export const setShowScrapeResultsSidebarState = (newValue: boolean) =>
  showScrapeResultsSidebarState(newValue);

// State that holds the internal users email if available
export const internalUserState = makeVar<string | null>(null);
export const useInternalUserState = () => useReactiveVar(internalUserState);
export const setInternalUserState = (newValue: string) =>
  internalUserState(newValue);

// State that determines whether/not we show the sign in modal
interface SignInModalState {
  show: boolean;
  source?: string | null;
}
export const showSignInModalState = makeVar<SignInModalState>({
  show: false,
  source: "",
});
export const useShowSignInModalState = () =>
  useReactiveVar(showSignInModalState);
export const setShowSignInModalState = (newValue: SignInModalState) =>
  showSignInModalState(newValue);
