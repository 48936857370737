import { useShowSearchFeedbackState } from "@/lib/graphql/state";
import { MixpanelEventName } from "@/types";
import { ArrowUpIcon } from "@phiaco/phia-ui";
import { customCN as cn } from "@phiaco/phia-ui/dist/util";
import { useEffect, useState } from "react";
import NoSSR from "./NoSSR";
import Button from "./shared/Button";

export default function BackToTop() {
  const [isVisible, setIsVisible] = useState(false);
  const showSearchFeedback = useShowSearchFeedbackState();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 250) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <NoSSR>
      {isVisible && (
        <Button
          eventName={MixpanelEventName.BACK_TO_TOP_CLICKED}
          // Modifying button styles to match the mocks (since mocks are using badge component which might not be suitable here),
          className={cn(
            "fixed bottom-4 right-8 rounded-full bg-bg-primary shadow-none sm:shadow-shallowBelow md:bottom-8 md:right-20 md:rounded-[20px]",
            // Move the back to top button to the left when the search feedback prompt is visible
            { "left-4 md:left-12": showSearchFeedback }
          )}
          // NOTE: We have to add the background color, box shadow, and border-radius to the innerClassName as well to ensure the styles work on mobile
          // I realize this is extremely hacky and we should fix with an overhaul of Button as the number of layers in that component is unnecessary
          innerClassName="p-0 h-8 w-8 label-sm text-cn-primary border-none bg-bg-primary rounded-full md:px-md md:py-sm md:h-[unset] shadow-shallowBelow sm:shadow-none md:w-[unset] gap-xs"
          onClick={scrollToTop}>
          <ArrowUpIcon variant="arrow" size={16} />
          <span className="hidden md:inline-block">Back to top</span>
        </Button>
      )}
    </NoSSR>
  );
}
