"use client";

import { usePathname, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";

/**
 * @ref https://nextjs.org/docs/app/api-reference/functions/use-router#router-events
 */
export function useNavigationEvents() {
  const [url, setUrl] = useState("");
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    const url = `${pathname}?${searchParams}`;
    setUrl(url);
  }, [pathname, searchParams]);

  return url;
}
