import Script from "next/script";

/**
 * The HubSpot tracking code is unique to each HubSpot account and allows HubSpot to
 * monitor your website traffic. The tracking code is automatically included on HubSpot
 * blog pages, landing pages, and website pages.
 *
 * To install the tracking code, paste the code before the closing </body> tag in the
 * HTML code for each page of your site.
 * @ref https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code
 */
export default function HubspotTrackingCode() {
  return (
    // <!-- Start of HubSpot Embed Code -->
    <Script
      async
      defer
      type="text/javascript"
      id="hs-script-loader"
      src="//js-na1.hs-scripts.com/47757743.js"
      strategy="afterInteractive"></Script>
    // <!-- End of HubSpot Embed Code -->
  );
}
