import { maliciousKeywords } from "./data";

// Helper function to normalize the input URL by adding "https://" if necessary, removing query params, trimming trailing slashes, and removing ".html".
export function normalizeUrl(input: string): string | null {
  // If the input doesn't start with http:// or https://, prepend http://
  if (!/^https?:\/\//i.test(input)) {
    // Regular expression to check if the input resembles a domain or valid URL path
    const domainPattern = /^[a-z0-9.-]+\.[a-z]{2,}(\/[^\s]*)?$/i;

    if (domainPattern.test(input)) {
      input = "https://" + input; // Prepend https:// if necessary
    } else {
      return null; // Return null if the input doesn't look like a valid domain
    }
  }

  try {
    // Checking if the input is a valid URL
    new URL(input);

    // Return as it is if the URL is valid
    return input;
  } catch (e) {
    return null; // Return null if the URL is not valid
  }
}

export function isValidUrl(input: string): boolean {
  try {
    const normalizedUrl = normalizeUrl(input); // Normalize the input URL

    if (normalizedUrl === null) return false; // If normalization failed, return false

    // Validate the normalized URL
    new URL(normalizedUrl);
    return true;
  } catch (e) {
    return false;
  }
}

export function getDomain(url: string): string {
  const normalizedUrl = normalizeUrl(url); // Normalize the input URL

  if (normalizedUrl !== null && isValidUrl(normalizedUrl)) {
    const parsedUrl = new URL(normalizedUrl);
    return parsedUrl.hostname;
  }

  return ""; // Return null if the URL is not valid or normalization failed
}

export const LOG_UNAUTHED_SEARCH_COOKIE_NAME = "phia-unauthed-search";
export const SEARCH_LIMIT = Number.MAX_SAFE_INTEGER;
export const NUM_DAYS_UNTIL_EXPIRES = 7;

/**
 * Function to verify if the provided text is not equal to any of the malicious keywords.
 * @param {string} text - The text to check.
 * @returns {boolean} - Returns true if the text is not equal to any malicious keyword, false otherwise.
 */
export function verifySearchInput(text: string): boolean {
  return !maliciousKeywords.some(keyword => text === keyword);
}
