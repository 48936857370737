import useAnalytics from "@/hooks/useAnalytics";
import { LogEventProperties, MixpanelEventName } from "@/types";
// eslint-disable-next-line no-restricted-imports
import { ButtonLarge as SharedButtonLarge } from "@phiaco/phia-ui";
import { ButtonLargeProps as PhiaButtonLargeProps } from "@phiaco/phia-ui/dist/components/ButtonLarge/ButtonLarge";

export interface ButtonLargeProps extends PhiaButtonLargeProps {
  eventName: MixpanelEventName | null;
  eventProperties?: LogEventProperties;
}

const ButtonLarge: React.FC<ButtonLargeProps> = ({
  eventName,
  eventProperties,
  onClick,
  ...props
}) => {
  const { logMixpanelEvent } = useAnalytics();
  return (
    <SharedButtonLarge
      onClick={(e: React.SyntheticEvent<HTMLButtonElement>) => {
        onClick?.(e);
        if (eventName) {
          logMixpanelEvent({
            name: eventName,
            properties: eventProperties,
          });
        }
      }}
      {...props}
    />
  );
};

export default ButtonLarge;
