"use client";

import usePhiaSearchParams from "@/hooks/usePhiaSearchParams";
import Cookies from "js-cookie";
import { useEffect } from "react";

interface Props {
  defaultCookieOptions: Cookies.CookieAttributes;
}

export default function UTMParams({ defaultCookieOptions }: Props) {
  const searchParams = usePhiaSearchParams();

  useEffect(() => {
    const utmParams = {
      utm_id: searchParams.get("utm_id"),
      utm_source: searchParams.get("utm_source"),
      utm_medium: searchParams.get("utm_medium"),
      utm_campaign: searchParams.get("utm_campaign"),
      utm_term: searchParams.get("utm_term"),
      utm_content: searchParams.get("utm_content"),
    };

    Cookies.set(
      "phia_utm_params",
      JSON.stringify(utmParams),
      defaultCookieOptions
    );

    // Disabling so that it only runs once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
