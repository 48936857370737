import type { ScrapedProductOutput } from "@/__generated__/graphql";

export enum FilterParamKey {
  GENDERS = "genders",
  PRICES = "prices",
  SIZES = "sizes",
  CONDITIONS = "condition",
  COLORS = "colors",
  PARTNERS = "partners",
  BRANDS = "brands",
  AUTHENTICATED = "authenticated",
  RETURNABLE = "returnable",
  VINTAGE = "vintage",
  FREE_SHIPPING = "free-shipping",
  IS_USING_SIZE_PREFERENCES = "use-my-sizes",
}

export enum ProductSearchParams {
  SEARCH = "search",
  LINK = "link",
  IMAGE_URL = "imageUrl",
  IMAGE_BASE_64 = "image",
  SHOP_MORE = "shop_more",
  SORT_BY = "sortBy",
  CUSTOM_PRICE_RANGE = "priceRange",
  USE_MY_SIZE_COOKIE = "phia-use-my-sizes",
  SCRAPED_NAME = "sn",
  SCRAPED_IMAGE_URL = "siu",
  SCRAPED_PRICE = "sp",
  SCRAPED_BRAND = "sb",
  SCRAPED_SECONDHAND = "ss",
  SCRAPED_COLOR = "sc",
  SOURCE = "source",
  RESALE_INSIGHTS_REQUEST_ID = "resale_insights_request_id",
}

export enum ProductSortType {
  BestMatch = "BEST_MATCH",
  PriceHighToLow = "PRICE_HIGH_TO_LOW",
  PriceLowToHigh = "PRICE_LOW_TO_HIGH",
}

export type SearchParamKeys =
  (typeof FilterParamKey)[keyof typeof FilterParamKey];

export type ScrapedQueryOutput =
  | {
      products: {
        scrapedData: ScrapedProductOutput;
        numProducts: number;
        results: [];
      };
    }
  | undefined;
