export const maliciousKeywords = [
  "1",
  "12",
  "13",
  "2",
  "22",
  "404",
  "aaa",
  "about",
  "access",
  "admin",
  "admin-post",
  "ads",
  "ae",
  "aged",
  "alfa",
  "alfa-rex",
  "alfa-rex1",
  "app-ads",
  "as",
  "auth",
  "avaa",
  "backup",
  "bihnmimh",
  "block-bindings",
  "booyah",
  "bypass",
  "c99",
  "cache-compat",
  "casper",
  "cd",
  "chanel",
  "chosen",
  "class-ixr-base64-view",
  "classsmtps",
  "click",
  "cljntmcz",
  "cloud",
  "colors",
  "colour",
  "config",
  "configs",
  "cookie",
  "cron",
  "css",
  "csv",
  "currentbody",
  "dav",
  "defaults",
  "disagreed",
  "doc",
  "dropdown",
  "dxc",
  "edit",
  "extractable-loader-head",
  "f35",
  "favicon",
  "feeds",
  "fi2",
  "file",
  "files",
  "flower",
  "fox",
  "functions",
  "fw",
  "fxcexgle",
  "gecko-new",
  "global",
  "goat1",
  "goods",
  "hehe",
  "hello",
  "home",
  "hoot",
  "idhrilrlb",
  "ind",
  "index",
  "index4",
  "input",
  "inputs",
  "install",
  "ioxi-o",
  "jquery",
  "k",
  "link",
  "liverpoolstyle",
  "locale",
  "log",
  "m1",
  "menu",
  "meta",
  "myk4tjeffvo",
  "newfile",
  "nfxxuaa",
  "ok",
  "outlines",
  "ova",
  "patekphillip",
  "plugin",
  "plugins",
  "post",
  "radio",
  "raf",
  "randkeyword",
  "robots",
  "room",
  "root",
  "sellers",
  "shell",
  "shell20211028",
  "sitemap",
  "special",
  "spip",
  "system",
  "text",
  "theme",
  "themes",
  "update",
  "updates",
  "user",
  "v4",
  "version",
  "w",
  "wander",
  "westelm",
  "white",
  "wornon",
  "wp-2019",
  "wp-activate",
  "wp-admin",
  "wp-blog-header",
  "wp-configs",
  "wp-conflg",
  "wp-contentt",
  "wp-index",
  "wp-links",
  "wp-load",
  "wp-login",
  "wp-mail",
  "wp-sigunq",
  "wsa",
  "wsoyanz",
  "wxo",
  "xleet",
  "xmlrpc",
];
