"use client";

import usePhiaSearchParams from "@/hooks/usePhiaSearchParams";
import { routes } from "@/routes";
import { usePathname } from "next/navigation";
import posthog, { type PostHogConfig } from "posthog-js";
import { PostHogProvider as PHProvider } from "posthog-js/react";
import { useEffect } from "react";
import PostHogPageView from "./PostHogPageView";

const API_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY ?? "";
const OPTIONS: Partial<PostHogConfig> = {
  name: "WEBSITE",
  api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST ?? "",
  capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  capture_pageleave: true, // Enable pageleave capture
  capture_heatmaps: true,
  capture_performance: true,
  capture_exceptions: true,
};

export function PostHogProvider({ children }: { children: React.ReactNode }) {
  const pathname = usePathname();
  const params = usePhiaSearchParams();

  const distinctId = params.get("distinct_id") ?? undefined;
  const sessionId = params.get("session_id") ?? undefined;

  useEffect(() => {
    // Skip PostHog initialization for the landing page
    if (pathname === routes.HOME) {
      return;
    }

    // Initialize PostHog for all other routes
    posthog.init(API_KEY, {
      ...OPTIONS,
      // Step 2. Bootstrapping the IDs on the second website for cross-domain tracking
      // 1st step in `app/mobile/add-permisions`
      // The one thing we do differently here as opposed to the docs is we get the params
      // as search params instead of hash params
      // https://posthog.com/tutorials/cross-domain-tracking#bootstrapping-the-ids-on-the-second-website
      bootstrap: {
        distinctID: distinctId,
        sessionID: sessionId,
      },
    });

    // Clean up function
    return () => {
      // Properly deactivate PostHog when navigating back to landing page
      if (posthog.__loaded) {
        posthog.opt_out_capturing();
      }
    };
  }, [distinctId, pathname, sessionId]);

  // If we're on the landing page, just render children without PostHog
  if (pathname === routes.HOME) {
    return <>{children}</>;
  }

  return (
    <PHProvider client={posthog}>
      <PostHogPageView />
      {children}
    </PHProvider>
  );
}
