"use client";

import { ProductSearchOrigin } from "@/__generated__/graphql";
import { buildProductLink } from "@/app/_components/HomePage/LinkPasting/HeroBannerV2/utils";
import { useSearch } from "@/context/SearchContext";
import useAnalytics from "@/hooks/useAnalytics";
import { useUnauthedSearchCount } from "@/hooks/useUnauthedSearchCount";
import { MixpanelEventName } from "@/types";
import { Badge, LinkSimple, useTailwindBreakpoint } from "@phiaco/phia-ui";
import Image from "next/image";
import { useRouter } from "next/navigation";
import React, { memo } from "react";
import { getDomain } from "../SearchBar/utils";
import { POPULAR_SEARCHES } from "./utils";

interface SearchItemBoxProps {
  image: string;
  title: string;
  price: number;
  productUrl: string;
}

const SearchItemBox: React.FC<SearchItemBoxProps> = ({
  image,
  title,
  price,
  productUrl,
}) => {
  const website = getDomain(productUrl);
  const link = buildProductLink({
    title,
    image,
    productUrl,
    originalPrice: price,
    website,
    source: ProductSearchOrigin.PopularSearches,
  });
  const router = useRouter();
  const { logMixpanelEvent } = useAnalytics();
  const { setCookie } = useUnauthedSearchCount();
  const { hideSearchOverlay } = useSearch();

  return (
    <div
      onClick={() => {
        logMixpanelEvent({
          name: MixpanelEventName.SEARCH_OVERLAY_POPULAR_SEARCH_CLICKED,
        });
        setCookie();
        hideSearchOverlay();
        router.push(link);
      }}
      className="mt-4 grid w-[256px] cursor-pointer grid-cols-[56px_1fr] gap-lg rounded-md bg-bg-secondary p-sm">
      <div
        className="relative h-[56px] rounded-sm"
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%)`,
        }}>
        <Image
          className="rounded-sm object-cover object-center"
          src={image}
          alt="popular search item image"
          fill
        />
      </div>
      <div className="flex flex-col justify-between">
        <p className="label-sm w-[168px] text-wrap capitalize">{title}</p>
        <p className="para-xs text-ellipsis text-cn-tertiary">{website}</p>
      </div>
    </div>
  );
};

const PopularSearches: React.FC<{
  popularSearches: SearchItemBoxProps[];
}> = ({ popularSearches }) => (
  <div className="w-full p-xl">
    <p className="label-md">Popular Searches</p>
    <div className="no-scrollbar flex max-w-[390px] gap-md overflow-x-auto whitespace-nowrap sm:max-w-max">
      {popularSearches.map(el => {
        return <SearchItemBox key={el.image} {...el} />;
      })}
    </div>
  </div>
);

const SearchOverlay: React.FC = () => {
  const { isMobile } = useTailwindBreakpoint();

  return (
    <div className="pointer-events-auto mx-auto flex flex-col items-center pt-md lg:max-w-[unset]">
      <div className="mx-2 flex w-fit items-center justify-center gap-md rounded-md border border-border-opaque p-4">
        <Badge
          size={isMobile ? "xs" : "sm"}
          label="Tip"
          icon={<LinkSimple />}
        />
        <p className="label-xs text-cn-tertiary lg:label-md">
          Paste a link in the search bar to find the best resale deals
        </p>
      </div>
      <PopularSearches popularSearches={POPULAR_SEARCHES} />
    </div>
  );
};

export default memo(SearchOverlay);
